import request from '@/utils/request'

// 支付管理-列表
export function payList(data) {
    return request({
        url: '/order/pay/list',
        method: 'post',
        data
    })
}
// 支付-获取支付链接以及参数
export function pay(data) {
    return request({
        url: '/bank/zs/pay/url',
        method: 'post',
        data
    })
}
// 支付[分步]-创建支付记录
export function first(data) {
    return request({
        url: '/order/pay/add',
        method: 'post',
        data
    })
}
// 支付[分步]-获取支付链接以及参数
export function second(data) {
    return request({
        url: '/order/pay/url',
        method: 'post',
        data
    })
}
// 支付弹窗信息
export function info(params) {
    return request({
        url: '/order/pay/info',
        method: 'get',
        params
    })
}
// 列表-支付记录
export function list(data) {
    return request({
        url: '/order/cash/list',
        method: 'post',
        data
    })
}
// 按钮-关闭支付
export function closePay(params) {
    return request({
        url: '/order/pay/close/'+params,
        method: 'post',
   
    })
}
// 统计-支付完成度
export function progress(params) {
    return request({
        url: '/order/cash/progress',
        method: 'get',
        params
    })
}

// 验证银行卡是否浙商银行
export function valid(params) {
    return request({
        url: '/order/valid/zs',
        method: 'get',
        params
    })
}