<template>
      <div class="pwd_box">
        <input ref="pwd" type="tel" maxlength="6" v-model="msg" class="pwd" unselectable="on" @focus="isFocus = true" @blur="isFocus = false" />
        <ul class="pwd-wrap" @click="focus">
         <li :class="msg.length === i?'psd-blink':''" v-for="(item,i) in 6" :key="item">
           <i v-if="msg.length > i"></i>
           <span class="cursor" v-if="msg.length === i && isFocus"></span>
          </li>
        </ul>
        
      </div>
</template>
<script>
export default {
name: 'cashier',
    data() {
      return {        
        msg: '',//支付密码
        isFocus: false
      }
    },
     watch: {
     msg(curVal) {
      if(/[^\d]/g.test(curVal)) {
       this.msg = this.msg.replace(/[^\d]/g, '');
      }else if(this.msg.length===6){
          this.$emit("pwd",this.msg)
      }
     },
    },
    methods:{
      //输入支付密码
      focus() {
        this.$refs.pwd.focus();
       },
    }
}
</script>
<style lang="less" scoped>
.pwd_box {
    height: auto;
    display: inline-flex;
    input[type=tel] {
     width: 0.1px;
     height: 0.1px;
     color: transparent;
     // position: relative;
     // top: 23px;
     background: #000000;
     // left: 46px;
     border: none;
     font-size: 18px;
     opacity: 0;
     z-index: -1;
    }
    //光标
    .psd-blink {
     display: inline-block;
    //  background: url("./img/blink.gif") no-repeat center;
    }
    .pwd-wrap {
     width: 300px;
     height: 50px;
     padding-bottom: 1px;
     margin: 0 auto;
     background: #fff;
     border: 1px solid #ddd;
     display: flex;
     display: -webkit-box;
     display: -webkit-flex;
     cursor: pointer;
     // position: absolute;
     // left: 0;
     // right: 0;
     // top: 13%;
     z-index: 10;
     li {
      list-style-type: none;
      text-align: center;
      line-height: 50px;
      -webkit-box-flex: 1;
      flex: 1;
      -webkit-flex: 1;
      border-right: 1px solid #ddd;
      position: relative;
      &:last-child {
       border-right: 0;
      }
      i {
       height: 10px;
       width: 10px;
       border-radius: 50%;
       background: #000;
       display: inline-block;
      }
     }
    }
    button {
     position: relative;
     display: block;
     height: 41px;
     text-align: center;
     margin: 0 auto;
     margin-top: 70%;
     padding: 0 20px;
     border-radius: 5px;
     font-size: 16px;
     border: 1px solid #dddddd;
     background: #dddddd;
     color: #000000;
    }
   }
   .cursor {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #333333;
      width: 1px;
      height: 32px;
      animation: twinkling 1.2s infinite ease;
   }
   @keyframes twinkling {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
</style>