<template>
  <div class="paymentment">
    <div class="title">支付管理</div>
    <a-row class="contcat">
      <a-col :span="4">
        <!-- <a-button type="primary" class="cl69 btn">
          <a-icon type="credit-card" />合并付款
        </a-button> -->
      </a-col>
    </a-row>
    <div>
      <a-table
        rowKey="id"
        :columns="columns"
        :dataSource="date"
        size="middle"
        :pagination="{
          size: 'middle',
          current: getlist.pageNum,
          pageSize: getlist.pageSize,
          showSizeChanger: true,
          showQuickJumper: true,
          onShowSizeChange: (current, pageSize) => {
            getlist.pageSize = pageSize
            getlist.pageNum = 1
            this.getpayList()
          },
          onChange: (pageNum) => {
            getlist.pageNum = pageNum
            this.getpayList()
          }
        }"
      >
        <template slot="orderId" slot-scope="text">
          <router-link
            :to="`/order?id=${text}&entityId=${$route.query.entityId}&p=3`"
            class="id-primary"
            >{{ text }}</router-link
          >
        </template>
        <template slot="totalAmount" slot-scope="text">
          <span>{{ text.toFixed(2) }}</span>
        </template>
        <template slot="netAmount" slot-scope="text">
          <span>{{ text.toFixed(2) }}</span>
        </template>
        <template slot="taxAmount" slot-scope="text">
          <span>{{ text.toFixed(2) }}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <span class="cursor" v-if="!record.paymentNo" @click="pay(record)"
              >支付</span
            >
          </a-space>
        </template>
      </a-table>
      <a-modal
        title="支付"
        :visible="visible"
        :footer="null"
        :width="990"
        @cancel="handleCancel"
      >
        <a-row>
          <a-col :span="6" class="red">支付总额：￥{{ payAll.fee }}</a-col>
          <a-col :span="18" class="red"><a-space :size="30"><span>(贷款：￥ {{ payAll.netFee }}</span>
              <span>税款：￥{{ payAll.taxFee }})</span></a-space></a-col>
        </a-row>
        <a-row>
          <a-col :span="3" style="font-size: 16px; color: #333"
            >支付渠道：</a-col
          >
          <a-col :span="21">
            <a-radio-group v-model="radio" @change="onRadio">
              <a-radio
                class="l-flex-aligncenter"
                :style="radio == 1 ? radioStyle : ''"
                :value="1"
              >
                <div class="l-flex-between">
                  <div class="logo">
                    <img src="@/assets/images/u7404.png" alt="" />
                  </div>
                  <div class="l-flex-between" style="width: 570px">
                    <p class="pt20">网关支付</p>
                    <p>
                      <span>实时到账</span>&nbsp;&nbsp;
                      <span>手续费3元</span>
                    </p>
                  </div>
                </div>
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <form
          :action="form.reqUrl"
          method="post"
          v-show="false"
          target="_blank"
        >
          <input type="text" :value="form.method" name="method" />
          <input type="text" :value="form.version" name="version" />
          <input type="text" :value="form.merId" name="merId" />
          <input type="text" :value="form.date" name="date" />
          <input type="text" :value="form.token" name="token" />
          <input type="text" :value="form.signMethod" name="signMethod" />
          <input type="text" :value="form.sign" name="sign" />
          <input type="submit" ref="sub" />
        </form>
        <a-row class="zs" v-if="radio == 1">
          <a-col :span="6" class="left-c">选择支付账号</a-col>
          <a-col :span="18" class="pt20">
            <a-select
              v-model="bankSelect"
              @change="handleChange"
              style="width: 510px"
              ><a-select-option
                v-for="item in payAll.bankAccountList"
                :key="item.bankAccount"
                >{{
                  item.purchaserName +
                  ' ' +
                  item.bankAccount +
                  ' ' +
                  item.bankName
                }}</a-select-option
              ></a-select
            ></a-col
          >
          <a-col :span="6" class="left-c">支付账户类型</a-col>
          <a-col :span="18" class="pt20">
            <a-input value="企业" disabled style="width: 510px"></a-input>
          </a-col>
          <a-col :span="6" class="left-c">银行账号</a-col>
          <a-col :span="18" class="pt20">
            <a-input v-model="orders.bankNo" style="width: 510px"></a-input>
          </a-col>
        </a-row>

        <a-row class="zs" v-if="radio == 2">
          <a-col :span="4"></a-col>
          <a-col :span="20" class="left-b">中再物贸云余额支付密码：</a-col>
          <a-col :span="4"></a-col>
          <a-col :span="20" class="left-b">
            <PayMoney @pwd="msg = $event" />
          </a-col>
          <a-col :span="4"></a-col>
          <a-col :span="20" class="left-b">请输入6位数余额支付密码 </a-col>
        </a-row>
        <a-row class="next">
          <a-col :sapn="24" align="center">
            <a-button type="primary" @click="sure" :loading="loading">
              确认支付
            </a-button></a-col
          >
        </a-row>
      </a-modal>
      <img src="" alt="" />
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: '订单号',
    scopedSlots: { customRender: 'orderId' },
    dataIndex: 'orderId'
  },
  {
    title: '支付编码',
    dataIndex: 'paymentNo',
    scopedSlots: { customRender: 'paymentNo' }
  },
  {
    title: '卖家',
    dataIndex: 'supplierName'
  },
  {
    title: '支付总额(元)',
    align: 'right',
    dataIndex: 'totalAmount',
    scopedSlots: { customRender: 'totalAmount' }
  },
  {
    title: '货款(元)',
    align: 'right',
    dataIndex: 'netAmount',
    scopedSlots: { customRender: 'netAmount' }
  },
  {
    title: '税款(元)',
    align: 'right',
    dataIndex: 'taxAmount',
    scopedSlots: { customRender: 'taxAmount' }
  },
  {
    title: '支付生成时间',
    dataIndex: 'createdTime'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

import PayMoney from '@/components/payInput.vue'
import { payList, second, info } from '@/api/pay'
export default {
  components: {
    PayMoney
  },
  data() {
    this.dateFormat = 'YYYY-MM-DD'
    return {
      date: [],
      getlist: {
        pageNum: 1,
        pageSize: 10
      },
      columns,
      selectedRowKeys: [],
      visible: false,
      drawer: false,
      radio: 1,
      radioStyle: {
        background: 'rgba(255, 204, 102, 1)'
      },
      msg: '',
      // 银行支付
      form: {
        method: '',
        version: '',
        merId: '',
        date: '',
        token: '',
        sign: '',
        reqUrl: '',
        signMethod: ''
      },
      orders: {
        //支付
        purchaserId: this.$route.query.entityId,
        paymentId: '',
        payAmount: null,
        bankName: '',
        bankNo: ''
      },
      bankSelect: '',
      payAll: {
        bankAccountList: [],
        fee: undefined, //总费用
        netFee: undefined, //贷款
        taxFee: undefined //税款
      },
      loading: false
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            'selectedRows: ',
            selectedRows
          )
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === 'Disabled User',
            name: record.name
          }
        })
      }
    }
  },
  created() {
    this.getpayList()
  },
  methods: {
    // 获取列表
    getpayList() {
      const config = {
        purchaserId: this.$route.query.entityId,
        pageNum: this.getlist.pageNum,
        pageSize: this.getlist.pageSize
      }
      payList(config).then((res) => {
        this.date = res.data.list
      })
    },
    // 获取弹窗信息
    getInfo() {
      const config = {
        orderId: this.orders.orderId,
        paymentId: this.orders.paymentId,
        purchaserId: this.$route.query.entityId
      }
      info(config)
        .then((res) => {
          if (res.code === 200) {
            this.payAll = res.data
            this.bankSelect =
              res.data.bankAccountList[0].purchaserName +
              ' ' +
              res.data.bankAccountList[0].bankAccount +
              ' ' +
              res.data.bankAccountList[0].bankName
            this.orders.bankNo = res.data.bankAccountList[0].bankAccount
            this.orders.bankName = res.data.bankAccountList[0].bankName
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((r) => {
          console.log(r)
        })
    },
    onChange(date, dateString) {
      console.log(date, dateString)
    },
    onRadio(e) {
      this.radio = e.target.value
    },
    handleChange(e) {
      const bankNa = this.payAll.bankAccountList.find((item) => {
        return item.bankAccount === e
      })
      this.bankSelect =
        bankNa.purchaserName + ' ' + bankNa.bankAccount + ' ' + bankNa.bankName
      this.orders.bankName = bankNa.bankName
      this.orders.bankNo = e
    },
    // 取消支付
    handleCancel() {
      this.orders = {
        purchaserId: this.$route.query.entityId,
        orderId: '',
        payAmount: null,
        bankName: '',
        bankNo: ''
      }
      this.bankSelect = ''
      this.visible = false
    },
    //支付
    sure() {
      if (this.radio === 1) {
        if (!this.verify()) return
        this.loading = true
        second({ ...this.orders })
          .then((res) => {
            this.loading = false
            if (res.code === 200) {
              this.form = res.data
              this.$nextTick(() => {
                this.$refs.sub.click()
              })
            } else {
              this.$message.error(res.message)
            }
          })
          .catch((r) => {
            this.loading = false
            console.log(r)
          })
      } else if (this.radio === 2) {
        if (this.msg.length != 6) {
          this.$message.error('请输入6位支付密码')
        } else return
      }
    },
    pay(record) {
      this.orders.orderId = record.orderId
      this.orders.paymentId = record.id
      this.visible = true
      this.getInfo()
    },
    verify() {
      if (this.payAll.bankAccountList.length == 0) {
        this.$message.error('请先添加银行卡再进行支付')
      } else if (!this.orders.bankName) {
        this.$message.error('请选择支付银行账号')
      } else if (!this.orders.bankNo) {
        this.$message.error('请输入银行账号')
      } else return true
    }
  }
}
</script>

<style lang="less" scoped>
.paymentment {
  width: 100%;
  padding: 20px 10px 20px 20px;
  background-color: #ffffff;
  .title {
    padding-left: 10px;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 28px;
  }
  .contcat {
    padding: 40px 0 20px 0;
    .btn {
      border: none;
      border-radius: 3px;
      width: 130px;
      height: 38px;
    }
    .cl69 {
      background-color: rgba(132, 0, 255, 0.698039215686274);
    }
  }
  .cursor {
    cursor: pointer;
    color: #1890ff;
  }
}
// 支付样式
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 20px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
.red {
  color: red;
  font-size: 16px;
  font-weight: 700;
}
</style>